import React from 'react';
import { Button } from '@material-ui/core';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { FileUpload } from '@shared/components/file-upload';
import { Flex } from '@shared/components/flex';
import { getHTTPClient } from '@core/http-client';
// @ts-ignore
import blinkFile from '@shared/images/blink-file.png';

import { styles } from './BulkCreate.styles';
import { downloadFile } from '@shared/utils/file';
import { Loading } from '../loading';
const $http = getHTTPClient();
const BulkCreateComponent: React.FC<WithStyles<typeof styles>> = ({ classes }) => {
    const [file, setFile] = React.useState<any | undefined>(undefined);
    const [fileLoading, setFileLoading] = React.useState<boolean>(false);

    const handleChangeFile = async (file: File | File[]) => {
        setFile(file);
        handleUploadFile(file);
    };

    const handleUploadFile = async (files: File[] | File) => {
        const uploadedFile = Array.isArray(files) ? files[0] : files;

        if (uploadedFile) {
            setFileLoading(true);
            let bodyFormData = new FormData();

            bodyFormData.append('file', uploadedFile);

            const { data } = await $http.post(`/qr/bulkCreate`, bodyFormData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                },
                responseType: 'arraybuffer',
            });

            setFileLoading(false);
            downloadFile(data, 'QRBulk.zip', 'octet/stream');
        }
    };

    const handleRemoveFile = () => {
        setFile(undefined);
    };

    return (
        <div className={classes.root}>
            <span className={classes.subHeading}>Upload File</span>
            <Flex classes={{ root: classes.uploadContainer }}>
                <Flex direction="column">
                    <FileUpload
                        config={{
                            maxSize: 2097152,
                            accept: ['text/csv'],
                            multiple: false,
                        }}
                        onUpload={handleChangeFile}
                        classes={{ root: classes.fileUpload }}
                        uploadText='Upload CSV File'
                    />
                    {fileLoading && <Loading absolute />}
                    {file && (
                        <>
                            <div>{file.name}</div>
                            <Button variant="outlined" onClick={handleRemoveFile}>
                                Remove File
                            </Button>
                        </>
                    )}
                </Flex>
            </Flex>
        </div>
    );
};

export const BulkCreate = withStyles(styles)(BulkCreateComponent);
