import { createStyles } from '@core/theme/utils/create-styles';
export const styles = () =>
  createStyles({
    root: {
      width: '100%',
    },
    uploadContainer: {
      marginBottom: 20,
    },
    fileUpload: {
      marginBottom: 20,
    },
    subHeading: {
      display: 'block',
      marginBottom: 10,
      color: '#2a354f',
      fontSize: 16,
    }
  });
